import React from "react"
import SEO from "../components/utils/SEO"
import Layout from "../components/layout/Layout"

const KontaktPage = () => (
  <Layout>
    <SEO title="Kontakt" />

    <div className="pt-5 header">
      <h1>Kontakt</h1>
      <h2>Unser Kontaktadresse und verantwortlich für den Inhalt.</h2>
    </div>

    <h3 className="mt-0">Liechtensteiner Behindertenverband</h3>
    <p>
      Liechtensteiner Behindertenverband<br />
      Wiesengass 17<br />
      9494 Schaan<br />
      Tel. 00423 / 390 05 15
      </p>
    <p>
      <a rel="external" href="https://www.lbv.li/">www.lbv.li</a><br />
      <a href="mailto:lbv@lbv.li">lbv@lbv.li</a>
    </p>

    <h3>Umsetzung</h3>
    <p>
      Miratools AG<br />
      Kesse 12<br />
      9488 Schellenberg
    </p>
    <p>
      <a rel="external" href="https://www.miratools.com/">www.miratools.com</a><br />
      <a href="mailto:mail@miratools.com">mail@miratools.com</a>
    </p>

    <h3>Datenschutzerklärung</h3>
    <p>Wir erheben keine Daten beim Besuch dieser Seite.</p>
    <p>Beim Hos­ting der Web­site und der dar­über er­ho­be­nen Da­ten ar­bei­ten wir mit Auf­trags­ver­ar­bei­tern zu­sam­men.</p>
  </Layout>
)

export default KontaktPage
